import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useFirebase } from '@amit/auth';

import type { ClientOptions } from 'graphql-ws';

/**
 * Retrieves the Firebase authentication token for the current user.
 * @returns A promise resolving to the user's Firebase ID token or undefined if the user is not authenticated.
 */
export async function getToken(): Promise<string | undefined> {
  const auth = useFirebase();
  return auth.currentUser?.getIdToken();
}

// Active WebSocket and timeout for managing ping/pong
let activeSocket: WebSocket | undefined;
let timedOut: ReturnType<typeof setTimeout> | undefined;

// Create a GraphQL WebSocket link
const WebSocketLink: ApolloLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_API_WS as string,
    connectionParams: async (): Promise<{ token: string | undefined }> => {
      const token = await getToken();
      return { token };
    },
    keepAlive: 10000, // Ping server every 10 seconds
    on: {
      connected: (socket: WebSocket) => {
        activeSocket = socket;
      },
      ping: (received: boolean) => {
        if (!received) {
          // If no pong is received within 5 seconds, reload the page
          timedOut = setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      },
      pong: (received: boolean) => {
        if (received && timedOut) {
          clearTimeout(timedOut); // Pong received, clear the timeout
        }
      },
      error: () => {
        if (activeSocket?.readyState === WebSocket.CLOSED) {
          window.location.reload();
        }
      },
    },
  } as ClientOptions),
);

// Configure Apollo Client
const apolloClient: ApolloClient<any> = new ApolloClient({
  link: WebSocketLink,
  cache: new InMemoryCache(),
});

export default apolloClient;
