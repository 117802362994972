import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchCourse = id => {
  return useApi(http.get(`/courses/${id}`));
};

export const fetchCourses = () => {
  return useApi(http.get('/courses'));
};

export function fetchExercises(course, lesson) {
  return useApi(http.get(`/courses/${course}/lessons/${lesson}/exercises`));
}
