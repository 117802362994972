import { createApp, defineAsyncComponent, h, provide } from 'vue';
import { createPinia } from 'pinia';
import { router } from '/@/router';

import { ApolloClients } from '@vue/apollo-composable';
import apolloClient from '@amit/composables/src/useSocket';

import {
  SentryPlugin,
  i18nPlugin,
  PrimeVuePlugin,
  VersionPlugin,
} from '@amit/composables/plugins';
import { createAuthService, Origin, Scope } from '@amit/auth';

import App from './App.vue';
import NotificationToast from '/@/components/NotificationToast.vue';

import 'vue-loading-overlay/dist/css/index.css';
import './styles/base.css';

const store = createPinia();
const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },
  render: () => h(App),
});

app.use(createAuthService(router), {
  origin: Origin.Students,
  scope: [Scope.Student, Scope.Teacher],
});

app.use(store);
app.use(SentryPlugin, { router, store });
app.use(i18nPlugin);
app.use(PrimeVuePlugin);
app.use(VersionPlugin);
app.use(router);

app.component('NotificationToast', NotificationToast);
app.component(
  'LoadingOverlay',
  defineAsyncComponent(() => import('vue-loading-overlay')),
);

app.mount('#app');
