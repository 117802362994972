<script lang="ts">
import { defineComponent, PropType, defineAsyncComponent, computed } from 'vue';
import fingerLeftIcon from '/@/assets/leadingIcon1.svg?url';
import { INotification } from '/@/types/interfaces';
import { clearToasts } from 'mosha-vue-toastify';
import { useCourseStore } from '/@/store/course';
import { get } from 'lodash';

const ExclamationCircleComponent = defineAsyncComponent(
  () => import('/@/assets/exclamation_circle.svg?component'),
);

export default defineComponent({
  name: 'UserNotification',
  components: { ExclamationCircleComponent },
  props: {
    notification: {
      type: Object as PropType<INotification>,
      required: true,
    },
    router: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const clear = () => clearToasts();
    const courseStore = useCourseStore();
    const courseCaption = computed(() => courseStore.course.caption);

    const lesson = get(props.notification, 'metadata.lesson', null);
    const outlineLesson = lesson ? courseStore.getOutlineLesson(lesson) : {};
    const lessonTitle = get(outlineLesson, 'title', '');

    const goToInbox = () => {
      const myRouter = props.router;
      myRouter.push({
        name: 'app-inbox',
        params: { id: props.notification.id },
      });
      clear();
    };

    return {
      fingerLeftIcon,
      lessonTitle,
      courseCaption,
      goToInbox,
      clear,
    };
  },
});
</script>

<template>
  <div class="w-full">
    <div class="mx-3 flex flex-col w-full">
      <div class="flex">
        <ExclamationCircleComponent :class="notification.level + '-toast'" />
        <span class="font-semibold text-dark pr-2">
          {{ notification.title }}
        </span>
      </div>
      <span v-if="lessonTitle" class="font-simplerBold text-dark pr-7">
        {{ courseCaption }} - {{ lessonTitle }}
      </span>
      <div class="font-semibold text-medium text-sm pr-7">
        {{ notification.content }}
      </div>
      <!-- <div class="flex items-center justify-end pt-4"> -->
      <!--        <span class="cursor-pointer font-semibold text-dark pl-11">הזכר לי מאוחר יותר</span>-->
      <!-- <div
          v-if="notification.id"
          class="cursor-pointer font-semibold text-primary pl-2 flex gap-1 items-center"
          @click="goToInbox">
          קח אותי לשם
          <img :src="fingerLeftIcon" alt="finger_left_icon" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<style>
.info-toast path {
  fill: #6366f1;
}

.warning-toast path {
  fill: #f59e0b;
}

.danger-toast path {
  fill: #f43f5e;
}

.success-toast path {
  fill: #10b981;
}

.default-toast path {
  fill: #94a3b8;
}
</style>
