<template>
  <MainLayout
    :user="accountStore.user"
    :school="accountStore.user.school"
    :system="accountStore.config">
    <RouterView v-slot="{ Component, route }">
      <Transition
        enter-active-class="transition transform ease-out duration-500"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition transform ease-in duration-300"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95">
        <KeepAlive>
          <div>
            <component :is="Component" :key="route.name" />
          </div>
        </KeepAlive>
      </Transition>
    </RouterView>
  </MainLayout>
</template>

<script setup lang="ts">
import {
  MainLayout,
  usePrimaryLinks,
  useUserLinks,
  useNavigation,
} from '@amit/layout';
import emitter from '@amit/composables/src/useBus';

import { useAccountStore } from '/@/store/account';
import { useRouter } from 'vue-router';
import { useAuth } from '@amit/auth';
import { computed, provide } from 'vue';

const accountStore = useAccountStore();

provide('events', emitter);

useNavigation(
  computed({
    get() {
      return accountStore.courseLinks;
    },

    set() {
      // accountStore.courseLinks = []
    },
  }),
);
usePrimaryLinks([
  {
    title: 'הקורסים שלי',
    path: 'student-dashboard',
    iconName: 'LayoutDashboard',
  },
]);
useUserLinks([
  {
    text: 'מרחב אישי',
    to: { name: 'student-dashboard' },
  },
  {
    text: 'החלפת אווטאר',
    event: 'user:avatar',
  },
  {
    text: 'חשבון שלי',
    enabled: !!accountStore.user?.sso_id,
    link: import.meta.env.VITE_SSO_PROFILE_LINK,
  },
  {
    text: 'התנתקות',
    event: 'user:logout',
  },
]);

const { logout } = useAuth();
const router = useRouter();

emitter.on('user:logout', () => {
  logout();
});

emitter.on('user:avatar', async () => {
  await router.push({ name: 'student-dashboard' });
  emitter.emit('openAvatarModal');
});
</script>
