import Breakpoints from 'vue-next-breakpoints';
import { breakpointsTailwind } from '@vueuse/core';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';

export interface MediaComposition {
  breakpoints: object;
  isMobile: ComputedRef<boolean>;
}

export function useBreakpoints(): MediaComposition {
  const breakpoints = Breakpoints(breakpointsTailwind);
  const isMobile = computed(() => breakpoints.md.matches);

  return {
    breakpoints,
    isMobile,
  };
}
