<template>
  <img :src="Auto" class="absolute h-full object-cover" alt="auto" />
  <div class="absolute h-full" :style="{ width: containerWidth }">
    <div class="relative h-full w-full">
      <div
        class="absolute top-1/100 right-39/100 bottom-85/100 left-45/100 flex flex-col text-center cursor-pointer"
        @click="
          router.push({
            name: 'course-dashboard',
            params: {
              course: route.params.course,
            },
          })
        ">
        <span class="element-title">מראה אחורית</span>
        <span class="element-description text-[3vh]">חזרה לעמוד הקורס</span>
      </div>
      <div
        class="absolute top-35/100 right-55/100 bottom-40/100 left-21/100 flex flex-col text-center"
        :class="{ 'cursor-pointer': currentCarPart === 4 }"
        @click="enterLesson(4)">
        <div class="relative w-full h-full flex flex-col text-center">
          <span class="element-title">לוח מחוונים</span>
          <span class="element-description text-[2.5vh]">בקרה בזמן אמת</span>
          <img
            v-if="currentCarPart === 4"
            class="animate-blink absolute top-0 right-0 bottom-0 h-full w-full"
            :src="CarBoardShadow"
            alt="mirror_button"/>
        </div>
      </div>
      <div
        class="absolute top-57/100 right-65/100 bottom-18/100 left-24.5/100 flex flex-col justify-center text-center"
        :class="{ 'cursor-pointer': currentCarPart === 3 }"
        @click="enterLesson(3)">
        <div
          class="relative w-full h-full flex flex-col text-center justify-center">
          <span class="element-title">הגה</span>
          <span class="element-description text-[3.5vh] leading-[4.5vh]">
            יישום
            <br />
            וניהול
            <br />
            זמן
          </span>
          <img
            v-if="currentCarPart === 3"
            class="animate-blink absolute top-0 right-0 h-full w-full"
            :src="CarWheelShadow"
            alt="wheel_button"/>
        </div>
      </div>
      <div
        class="absolute top-45/100 right-39/100 bottom-22/100 left-45/100"
        :class="{ 'cursor-pointer': currentCarPart === 2 }"
        @click="enterLesson(2)">
        <div
          class="relative w-full h-full flex flex-col text-center justify-end">
          <span class="element-title">מערכת הניווט</span>
          <span class="element-description text-[2.5vh]">תכנון סדר פעולות</span>
          <img
            v-if="currentCarPart === 2"
            class="animate-blink absolute top-0 right-0 h-full w-full"
            :src="CarGpsShadow"
            alt="gps_button"/>
        </div>
      </div>
      <div
        class="absolute top-75/100 right-41/100 bottom-1/100 left-47/100"
        :class="{ 'cursor-pointer': currentCarPart === 1 }"
        @click="enterLesson(1)">
        <div
          class="relative w-full h-full flex flex-col text-center justify-end">
          <span class="element-title">כפתור התנעה</span>
          <span class="element-description text-[3vh]">הצבת מטרות</span>
          <img
            v-if="currentCarPart === 1"
            class="animate-blink absolute top-0 right-0 h-80/100 w-full"
            :src="CarStartShadow"
            alt="start_button"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAccountStore } from '/@/store/account';
import { IEnrollment } from '/@/types/interfaces';
import Auto from '/@/assets/auto.jpg';
import CarStartShadow from '/@/assets/carStartShadow.svg?url';
import CarBoardShadow from '/@/assets/carBoardShadow.png';
import CarGpsShadow from '/@/assets/carGpsShadow.png';
import CarWheelShadow from '/@/assets/carWheelShadow.png';
import swal from 'sweetalert';

defineProps({
  containerWidth: {
    type: String,
    default: 'auto',
  },
});

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const accountStore = useAccountStore();

// FIXME: To be changed....urgent !!!
const lessonsCarId = {
  '63a33aaaf11bb8563a492210': 1,
  '63a33aaaf11bb8563a492211': 2,
  '63a33aaaf11bb8563a492212': 3,
  '63a33aaaf11bb8563a492213': 4,
  '63a33aaaf11bb8563a492214': 2,
  '63a33aaaf11bb8563a492215': 3,
  '63a33aaaf11bb8563a492216': 4,
  '63a33aaaf11bb8563a492217': 3,
  '63a33aaaf11bb8563a492218': 3,
  '63a33aaaf11bb8563a492219': 3,
  '63a33aaaf11bb8563a49221a': 3,
};

const currentEnrollment = computed(
  () => accountStore.getCurrentEnrollment(route.params.course) as IEnrollment,
);
const currentLesson = computed(() => currentEnrollment.value?.current_lesson);
const currentCarPart = computed(
  () => currentLesson.value && lessonsCarId[currentLesson.value],
);

const canEnter = lessonId => {
  const currentLessonEnrollment = currentEnrollment.value.summary.find(
    lesson => lesson.lesson.id === lessonId,
  );
  return !currentLessonEnrollment?.locked;
};
const enterLesson = partId => {
  if (partId !== currentCarPart.value) {
    return;
  }

  if (!canEnter(currentLesson.value)) {
    swal(t('menu.locked_lesson'), { buttons: [false] });
    return;
  }

  router.push({
    name: 'skill-lesson',
    params: {
      lesson: currentLesson.value,
    },
  });
};
</script>

<style scoped>
.element-title {
  @apply font-simplerBold  text-[3.5vh] leading-[3.5vh] text-[#00ffff];
}
.element-description {
  @apply text-white font-simplerRegular;
}
</style>
