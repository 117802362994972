<template>
  <div ref="vueHotspotFrame" class="h-full w-full relative inline-block">
    <!-- image -->
    <img
      ref="vueHotspotBackgroundImage"
      class="max-w-full w-full h-full object-contain ui__vue_hotspot_background_image"
      :src="props.landingPage?.image"
      alt="Hotspot Image"
      :style="{
        visibility: imageLoaded ? 'visible' : 'hidden',
      }"
      @load="successLoadImg"/>
    <!-- hotspot -->
    <HotSpot
      v-for="(part, index) in props.landingPage?.parts"
      :key="index"
      :x="part.x"
      :y="part.y"
      :title="part.title"
      :message="part.message"
      :image-loaded="imageLoaded"
      :vue-hotspot-background-image="vueHotspotBackgroundImage"
      :vue-hotspot="vueHotspotFrame"
      :is-clickable="part.key === activePart"
      @click="handleHotspotClick(part)"/>

    <button
      v-if="imageLoaded"
      :style="backButtonStyles"
      class="absolute p-[12px] rounded-[12px] border border-solid border-1 border-[#cbd5e1] text-[#64748B] bg-white text-sm font-anomaliaBold"
      @click="
        router.push({
          name: 'course-dashboard',
          params: {
            course: route.params.course,
          },
        })
      ">
      <span class="text-sm font-anomaliaBold">
        {{
          !leftDir
            ? t('course_landing_page.back_button')
            : t('course_landing_page.back_button_en')
        }}
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, reactive, ref, toRefs, watchEffect } from 'vue';
import { IEnrollment, ILandingPage } from '/@/types/interfaces';
import { useAccountStore } from '/@/store/account';
import { useRoute, useRouter } from 'vue-router';
import HotSpot from '/@/components/landingPage/HotSpot.vue';
import { useCourseStore } from '/@/store/course';
import swal from 'sweetalert';
import emitter from '@amit/composables/src/useBus';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const accountStore = useAccountStore();
const courseStore = useCourseStore();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  landingPage: {
    type: Object as PropType<ILandingPage>,
    default: () => {},
  },
});

const imageLoaded = ref(false);
const vueHotspotBackgroundImage = ref<HTMLImageElement>({} as HTMLImageElement);
const vueHotspotFrame = ref({} as HTMLImageElement);
const currentEnrollment = computed(
  () => accountStore.getCurrentEnrollment(route.params.course) as IEnrollment,
);
const currentLesson = computed(
  () => currentEnrollment.value?.current_lesson as string,
);
const currentOutlineLesson = computed(() =>
  courseStore.getCourseOutlineLesson(currentLesson.value),
);

const lastOutlineLesson = computed(() => {
  const finishedLesson = route.query.finishedLesson;
  return courseStore.getCourseOutlineLesson(finishedLesson);
});

const activePart = computed(() => currentOutlineLesson.value?.part);
const leftDir = computed(() => {
  if (currentOutlineLesson.value)
    return !currentOutlineLesson.value.display?.rtl;
  return !lastOutlineLesson.value?.display?.rtl;
});

const backButtonPostion = reactive({
  positionBottom: 0,
  positionX: 30,
});

const menuButtonPostion = reactive({
  positionTop: 0,
  positionX: 0,
});

const frameSize = reactive({
  overlayHeight: '0%',
  overlayWidth: '0%',
  overlayLeft: '0px',
  overlayTop: '0px',
});
toRefs(frameSize);

const backButtonStyles = ref({
  bottom: '0',
  right: 'unset',
  left: 'unset',
});

watchEffect(() => {
  if (imageLoaded.value) {
    getButtonsPosition();
  }
});

const getButtonsPosition = () => {
  const frameRect = vueHotspotFrame.value?.getBoundingClientRect();
  const imageRect = vueHotspotBackgroundImage.value?.getBoundingClientRect();
  const distanceFromBottom = frameRect?.bottom - imageRect?.bottom;
  backButtonPostion.positionBottom = 30 + distanceFromBottom;

  const aspectRatio =
    vueHotspotBackgroundImage.value?.naturalHeight /
    vueHotspotBackgroundImage?.value?.naturalWidth;
  const desiredWidth = vueHotspotBackgroundImage.value?.clientWidth; // Set your desired width here
  const imageHeight = desiredWidth * aspectRatio;
  backButtonPostion.positionBottom =
    (window.innerHeight - imageHeight) / 2 + (imageHeight * 3) / 100;

  backButtonStyles.value.bottom = `${backButtonPostion?.positionBottom}px`;
  backButtonStyles.value.right = leftDir?.value
    ? `${backButtonPostion?.positionX}px`
    : 'unset';
  backButtonStyles.value.left = !leftDir?.value
    ? `${backButtonPostion?.positionX}px`
    : 'unset';

  //menu button
  menuButtonPostion.positionTop = (window.innerHeight - imageHeight) / 2 + 30;
  menuButtonPostion.positionX =
    (window.innerWidth - vueHotspotBackgroundImage.value?.clientWidth) / 2 + 30;
  emitter.emit('landing-image-loaded', menuButtonPostion);
};

window.addEventListener('resize', getButtonsPosition);

function successLoadImg(event) {
  if (event.target.complete === true) {
    imageLoaded.value = true;
    emitter.emit('landing-image-loaded', {});
  }
}

const handleHotspotClick = part => {
  if (part.key === activePart.value) {
    const isNextLessonLocked = currentEnrollment.value.summary.find(item => {
      return item.lesson._id === currentLesson.value;
    })?.locked;
    if (isNextLessonLocked) {
      swal(t('menu.locked_lesson'), { buttons: [false] });
      return;
    }
    router.push({
      name: 'skill-lesson',
      params: {
        lesson: currentLesson.value,
      },
    });
  }
};
</script>

<style scoped></style>
