<template>
  <div
    class="flex justify-center relative h-screen w-screen overflow-hidden bg-white">
    <LessonsMenu />

    <div
      v-if="isMobile && isPortrait"
      class="flex flex-col justify-center h-screen w-full absolute z-[1050] bg-white">
      <div class="px-8 text-center text-dark font-simplerBold text-2xl">
        {{ t('home.rotate_the_device') }}
      </div>
      <LottieAnimation :animation-data="MobileAnimation" loop />
    </div>

    <AutoNoami v-if="courseId === skilyId" :container-width="containerWidth" />
    <div v-else>
      <LandingPage
        :landing-page="courseStore?.course?.theme?.landingPage"
        :left-dir="courseId === englishId"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useBreakpoints } from '@amit/composables';
import { useCourseStore } from '/@/store/course';
import { useAccountStore } from '/@/store/account';
import LessonsMenu from '/@/components/LessonsMenu.vue';
import AutoNoami from '/@/components/AutoNoami.vue';
import LandingPage from '/@/components/landingPage/LandingPage.vue';
import MobileAnimation from '/@/assets/animations/mobile.json';
import swal from 'sweetalert';
import { LottieAnimation } from 'lottie-web-vue';

const { t } = useI18n();
const { isMobile } = useBreakpoints();
const courseStore = useCourseStore();
const accountStore = useAccountStore();
const route = useRoute();
const containerWidth = ref();
const router = useRouter();

const isPortrait = ref(true);
const skilyId = '63a1001b6376e0feb759b966';
const englishId = '65676f5905be00fb1d90a29d';
const courseId = route.params.course as string;

const setIsPortrait = () => {
  isPortrait.value = window.matchMedia('(orientation: portrait)').matches;
};

const setContainerWidth = () => {
  containerWidth.value =
    Number(document.documentElement.scrollHeight / 0.45).toFixed(2) + 'px';
};
onMounted(() => {
  window.onresize = () => {
    setContainerWidth();
    setIsPortrait();
  };
  setContainerWidth();
  setIsPortrait();
});

watch(
  () => courseStore.course,
  () => {
    setSubjectByPart();
    if (route.query.finishedLesson) {
      let finalMessage = courseStore.getCourseOutlineLesson(
        route.query.finishedLesson,
      )?.final_message;
      if (!finalMessage) {
        finalMessage = 'סיימת את השיעור';
      }

      swal({
        text: finalMessage,
        icon: 'success',
        buttons: 'OK' as any,
      }).then(() => {
        if (
          !courseStore?.course?.theme?.landingPage?.image &&
          courseId !== skilyId
        ) {
          router.push({
            name: 'course-dashboard',
            params: {
              course: courseId,
            },
          });
        }
      });
    }
  },
);

const setSubjectByPart = () => {
  courseStore.course.outline = courseStore.course?.outline?.map(outline => {
    let part = courseStore?.course?.theme?.landingPage?.parts.find(
      p => p.key == outline.part,
    );
    return part ? { ...outline, subject: part.message } : outline;
  });
};

onMounted(() => {
  accountStore.setEnrollments(courseId);
});
</script>
