import { defineStore } from 'pinia';
import { INotification } from '/@/types/interfaces';
import { isEmpty } from 'lodash';
import { collect } from 'collect.js';
import { dateFormat } from '/@/services/notifications';
import {
  fetchNotifications,
  markNotificationAsRead,
} from '/@/services/notifications';

export const useNotificationStore = defineStore({
  id: 'notifications',
  state: () => ({
    notifications: [] as any[],
    currentNotification: {} as INotification | any,
  }),

  getters: {
    getUnreadNotifications: state => {
      return state.notifications.filter(
        (notification: INotification) => !notification.read_at,
      );
    },
    getUnreadFeedbackNotifications: state => {
      return state.notifications.filter(
        (notification: INotification) =>
          !notification.read_at && notification.type === 'feedback',
      );
    },
    getCurrentIndex: state => {
      if (isEmpty(state.currentNotification)) {
        return 0;
      }
      return state.notifications.findIndex(
        (notification: INotification) =>
          notification.id === state.currentNotification.id,
      );
    },
  },
  actions: {
    addNotification(notification: INotification) {
      const formattedNotification = { ...notification };
      formattedNotification.created_on = dateFormat(notification.created_on);
      this.notifications.unshift(formattedNotification);
    },
    setCurrentNotification(notification: any, studentId: string) {
      if (studentId && !isEmpty(notification) && !notification.read_at) {
        markNotificationAsRead({ studentId, notificationId: notification.id });
      }
      const currentNotification = this.notifications.find(
        (n: INotification) => n.id === notification.id,
      );
      if (!currentNotification) {
        return;
      }
      currentNotification.read_at = new Date();
      this.currentNotification = currentNotification;
    },
    goToPreviousNotification() {
      const index = this.notifications.indexOf(this.currentNotification);
      if (index > 0) {
        this.currentNotification = this.notifications[index - 1];
      }
    },
    goToNextNotification() {
      const index = this.notifications.indexOf(this.currentNotification);
      if (index < this.notifications.length - 1) {
        this.currentNotification = this.notifications[index + 1];
      }
    },
    getMessages(userId: string) {
      if (isEmpty(this.notifications)) {
        const { onSuccess, onError } = fetchNotifications({ id: userId });

        onSuccess(data => {
          const formattedData = collect(data.data)
            .sortByDesc(notification => {
              return new Date(notification.created_on);
            })
            .toArray();
          this.notifications = formattedData;
          return formattedData;
        });
        onError(e => console.log(e));
      }
    },
    getTeacherFeedback(lesson: string, exercise: string) {
      const exerciseFeedback = this.notifications.filter(notification => {
        const { metadata = {} } = notification;
        return metadata.lesson === lesson && metadata.exercise === exercise;
      });

      if (!exerciseFeedback.length) {
        return '';
      }
      exerciseFeedback.sort(
        (a, b) =>
          new Date(b.created_on).getTime() - new Date(a.created_on).getTime(),
      );
      return exerciseFeedback.shift().content;
    },
    onUserLogout() {
      this.notifications = [] as INotification[];
    },
  },
});
