import { defineStore } from 'pinia';
import {
  fetchCourse,
  fetchCourses,
  fetchExercises as fetchExercisesData,
} from '/@/services/courses';
import { find } from 'lodash';
import { ICourse, ISkill } from '/@/types/interfaces';

export const useCourseStore = defineStore({
  id: 'course',
  state: () => ({
    course: {} as ICourse,
    skills: {} as ISkill,
    lessons: {} as { [lesson: string]: any },
    courses: [] as ICourse[],
    exercises: [],
  }),
  getters: {},
  actions: {
    fetchCourses() {
      const { onSuccess } = fetchCourses();
      onSuccess(({ data }) => {
        this.courses = data;
      });
    },
    fetchCourse(course) {
      this.course = {} as ICourse;
      const { onSuccess } = fetchCourse(course);
      onSuccess(({ data }) => {
        this.course = data;
      });
    },
    fetchExercises(course, lesson) {
      const { onSuccess } = fetchExercisesData(course, lesson);
      onSuccess(({ data }) => {
        this.exercises = data;
      });
    },
    getOutlineLesson(lessonId) {
      return find(this.lessons, { id: lessonId });
    },
    getCourseOutlineLesson(lessonId) {
      return this.course?.outline?.find(lesson => lesson.id === lessonId);
    },
    getLesson(lesson) {
      return this.lessons?.[lesson];
    },
    getExercise(exercise) {
      return this.exercises.find(({ id }) => id === exercise);
    },
  },
});
