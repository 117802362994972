import { format } from 'date-fns';
import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const dateFormat = timestamp => {
  const date = new Date(timestamp);
  return format(date, 'dd.MM.yy');
};

export const fetchNotifications = ({ id }) => {
  return useApi(http.get('/users/' + id + '/notifications'));
};

export const markNotificationAsRead = ({ studentId, notificationId }) => {
  return useApi(
    http.put(`/users/${studentId}/notifications/${notificationId}/read`),
  );
};
