<script setup lang="ts">
import { computed, ref, watch, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import minus from '/@/assets/minus.svg?url';
import StatusIcon from '/@/components/StatusIcon.vue';
import { useCourseStore } from '/@/store/course';
import { useAccountStore } from '/@/store/account';
import menuOpenButton from '/@/assets/menuOpenButton.svg?url';
import { groupBy, get } from 'lodash';
import swal from 'sweetalert';
import { Dot, Menu } from 'lucide-vue-next';
import { IEnrollment } from '/@/types/interfaces';
import emitter from '@amit/composables/src/useBus';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const showMenu = ref(false);
const lessonsLevel = ref(<any>{});
const enrollmentLevel = ref(<any>{});
const courseStore = useCourseStore();
const accountStore = useAccountStore();
const skilyId = '63a1001b6376e0feb759b966';
const courseId = route.params.course;
const course = computed(() => courseStore.course);
const courseLessons = computed(() => courseStore.course.outline);
const backgroundImagLoaded = ref(false);

const enrollment = computed(
  () => accountStore.getCurrentEnrollment(route.params.course) as IEnrollment,
);

const currentLesson = computed(
  () => enrollment.value?.current_lesson as string,
);

const flatLevel = ref(false);
const menuButtonPostion = reactive({
  positionTop: 0,
  positionX: 0,
});

emitter.on('landing-image-loaded', (menuPostion: any) => {
  backgroundImagLoaded.value = true;
  menuButtonPostion.positionTop = menuPostion.positionTop;
  menuButtonPostion.positionX = menuPostion.positionX;
});

onMounted(() => {
  courseStore.fetchCourse(route.params.course);
});

watch(
  () => courseLessons.value,
  courseLessons => {
    if (courseLessons) {
      lessonsLevel.value = groupBy(courseLessons, 'level');
      if (!courseLessons.some(item => 'level' in item)) {
        flatLevel.value = true;
      }
    }
  },
  { immediate: true },
);

watch(
  enrollment,
  enrollment => {
    if (enrollment?.summary) {
      enrollmentLevel.value = groupBy(enrollment.summary, 'level');
    }
  },
  { immediate: true },
);

const lessonProgress = computed(() => (level, lessonId) => {
  if (lessonId === currentLesson.value) {
    return '50';
  }
  return enrollmentLevel.value?.[level]?.find(
    ({ lesson }) => lesson.id === lessonId,
  )?.progress;
});

const isLessonAllowed = lessonId => {
  const enrollmentSummary = get(enrollment.value, 'summary', []);
  const lessonSummary = enrollmentSummary.find(
    ({ lesson }) => lesson.id === lessonId,
  );
  return lessonSummary && !lessonSummary.locked;
};

const getLevelStatus = level => {
  const levelData = get(enrollmentLevel.value, level, null);
  if (!levelData) {
    return;
  }

  if (levelData[levelData.length - 1].progress === 100) {
    return 'done';
  }
  if (
    levelData[0].progress > 0 ||
    levelData[0].lesson.id === currentLesson.value
  ) {
    return 'in_progress';
  }
};

const isLocked = lessonId => {
  const currentLessonEnrollment = enrollment.value.summary.find(
    lesson => lesson.lesson.id === lessonId,
  );
  return currentLessonEnrollment?.locked;
};
const enterLesson = lessonId => {
  if (!isLessonAllowed(lessonId)) return;

  if (isLocked(lessonId)) {
    swal(t('menu.locked_lesson'), { buttons: [false] });
    return;
  }

  router.push({
    name: 'skill-lesson',
    params: {
      skill: 'self-management',
      lesson: lessonId,
    },
  });
};
</script>

<template>
  <div
    class="fixed z-[1001] right-0 h-full lg:h-90/100"
    :class="{ 'lg:right-2/100 lg:top-3/100': skilyId === courseId }"
    :style="{
      marginRight:
        skilyId != courseId ? `${menuButtonPostion.positionX}px` : '0px',
    }">
    <div class="relative flex h-full">
      <button
        v-if="skilyId === courseId || backgroundImagLoaded"
        class="w-16.5 h-16.5 rounded-full lg:flex shadow-solid"
        :class="{
          'bg-white ': !showMenu,
          hidden: showMenu,
          'fixed top-3/100 right-2/100 lg:right-0 lg:top-0 lg:static':
            skilyId === courseId,
          absolute: skilyId != courseId,
        }"
        :style="{
          top: skilyId != courseId ? `${menuButtonPostion.positionTop}px` : '',
        }"
        @click="showMenu = !showMenu">
        <Menu v-if="!showMenu" class="m-auto" />
        <img
          v-else
          class="hidden lg:flex absolute top-0 -right-10"
          :src="menuOpenButton"
          alt="Close"/>
      </button>
      <div
        v-show="showMenu"
        class="flex py-0 lg:py-8 rounded-l-60 lg:rounded-60 lg:rounded-tr-70 bg-white">
        <div class="flex flex-col lg:space-y-5 h-full lg:h-97/100">
          <div
            class="lg:pr-10 lg:pl-25 flex items-center space-x-3 lg:space-x-reverse">
            <button
              class="w-10 h-16.5 lg:hidden flex justify-center items-center"
              @click="showMenu = !showMenu">
              <img :src="minus" alt="Close" />
            </button>
            <span class="font-simplerBold">
              {{ t('menu.title') }}
            </span>
            <div
              class="flex justify-center items-center text-xs leading-5 font-simplerBold border-2 border-primary-700 text-primary-700 rounded-full px-2.5">
              {{ course.caption }}
            </div>
          </div>
          <div
            class="pr-10 lg:pr-12 overflow-y-auto"
            :style="{ direction: 'ltr' }">
            <div class="flex" :style="{ direction: 'rtl' }">
              <div
                class="flex flex-row lg:flex-col lg:space-y-1 space-x-5 space-x-reverse lg:space-x-0 pl-2 lg:pl-0">
                <div
                  v-for="(level, key) in lessonsLevel"
                  :key="key"
                  class="my-2">
                  <div class="flex space-x-3 space-x-reverse">
                    <span v-if="!flatLevel" class="text-lg font-simplerBold">
                      {{ t(`level.${key}`) }}
                    </span>
                    <div
                      v-if="getLevelStatus(key)"
                      class="mt-0.5 w-16.25 flex justify-center items-center text-xs rounded-full py-1"
                      :class="
                        getLevelStatus(key) === 'done'
                          ? ' bg-green-100'
                          : 'bg-orange-100'
                      ">
                      {{ t(`menu.${getLevelStatus(key)}`) }}
                    </div>
                  </div>
                  <ul
                    v-for="(
                      { id, status, lesson, subject, title }, lessonOutlineIndex
                    ) in level"
                    :key="lessonOutlineIndex"
                    class="flex items-center">
                    <li class="relative py-1">
                      <div class="flex items-center">
                        <div
                          v-if="lessonOutlineIndex !== level.length - 1"
                          class="absolute top-0 right-0 h-full w-4 bg-secondary-200 self-start mr-2 translate-x-1/2 translate-y-5"
                          aria-hidden></div>
                        <status-icon
                          :progress="
                            lessonProgress(key, id)
                              ? Number(lessonProgress(key, id))
                              : 0
                          "
                          class="top-2"
                          :status="status"/>
                        <h3
                          class="font-simplerBold pr-7"
                          :class="[
                            isLessonAllowed(id)
                              ? 'text-primary-700 cursor-pointer'
                              : 'text-secondary-900',
                          ]"
                          @click="enterLesson(id)">
                          {{ t('menu.step') + lesson }}
                        </h3>
                      </div>
                      <ul class="pr-7">
                        <li v-if="subject" class="flex items-center">
                          <Dot />
                          <span
                            class="font-simplerBold"
                            :class="
                              lessonProgress(key, id)
                                ? 'text-default'
                                : 'text-secondary-900'
                            ">
                            {{ subject }}
                          </span>
                        </li>
                        <li class="flex items-center">
                          <Dot
                            :class="
                              lessonProgress(key, id)
                                ? 'stroke-default'
                                : 'stroke-secondary-900'
                            "/>
                          <span
                            class="font-simplerBold text-secondary-900"
                            :class="{
                              'text-default': lessonProgress(key, id) === 100,
                            }">
                            {{ title }}
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
