<template>
  <div
    class="absolute"
    :style="{
      top: `${conf.positionTop}px`,
      left: `${conf.positionLeft}px`,
      direction: 'ltr',
      cursor: isClickable ? 'pointer' : '',
    }">
    <!-- baseTooltip -->
    <BaseTooltip
      v-if="imageLoaded"
      theme="transparent"
      placement="top"
      :distance="basicTooltipDistance"
      :is-open="isClickable"
      :auto-hide="!isClickable"
      :trigger="isClickable ? '' : 'hover'"
      :popper-class="
        isClickable ? 'z-[1000] custum-popper-hotspot-animation ' : ' z-[1000] '
      "
      :popper-container="isClickable ? '#active-hotspot-img' : 'body'"
      :positioning-disabled="props.isClickable">
      <template #trigger>
        <div
          :id="isClickable ? 'active-hotspot-img' : ''"
          @click="hotSpotClickHandler">
          <img
            :src="HotSpotIcon"
            :style="hotSpotSvgStyle"
            :class="
              isClickable ? 'image-active-pulse-grow' : 'image-pulse-grow'
            "
            alt="part"/>
        </div>
      </template>

      <template #content>
        <div
          class="text-center text-[12px]"
          :class="{ 'cursor-pointer': props.isClickable }"
          @click="hotSpotClickHandler()">
          <div class="title font-anomaliaBold">
            {{ title }}
          </div>
          <div class="message font-anomaliaRegular">
            {{ message }}
          </div>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  onUnmounted,
  PropType,
  watch,
  reactive,
  computed,
} from 'vue';

import { BaseTooltip } from '@nwire/amit-design-system';
import HotSpotIcon from '/@/assets/hotSpotIcon.svg?url';

const props = defineProps({
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },

  imageLoaded: {
    type: Boolean,
    default: true,
  },
  vueHotspotBackgroundImage: {
    type: Object as PropType<HTMLImageElement>,
    default: {} as HTMLImageElement,
    required: true,
  },
  vueHotspot: {
    type: Object as PropType<HTMLDivElement>,
    required: true,
  },
  isClickable: {
    type: Boolean,
    default: false,
  },
});

const conf = reactive({
  positionTop: 0,
  positionLeft: 0,
});

const hotSpotSize = 32;
const basicTooltipDistance = 12;
const hotSpotSvgStyle = computed(() => {
  return {
    height: props.isClickable ? `${hotSpotSize}px` : `${hotSpotSize - 8}px`,
    width: props.isClickable ? `${hotSpotSize}px` : `${hotSpotSize - 8}px`,
  };
});
const emit = defineEmits(['click']);

onMounted(() => {
  window.addEventListener('resize', getHotspotStyle);
});

onUnmounted(() => {
  window.addEventListener('resize', getHotspotStyle);
});

watch(
  () => props.imageLoaded,
  loaded => {
    if (loaded) {
      getHotspotStyle();
    }
  },
);

function getHotspotStyle() {
  const aspectRatio =
    props.vueHotspotBackgroundImage.naturalHeight /
    props.vueHotspotBackgroundImage?.naturalWidth;
  const desiredWidth = props.vueHotspotBackgroundImage.clientWidth; // Set your desired width here
  const imageHeight = desiredWidth * aspectRatio;
  conf.positionTop =
    (props.y * imageHeight) / 100 + (window.innerHeight - imageHeight) / 2;
  conf.positionLeft =
    (props.x * props.vueHotspotBackgroundImage.clientWidth) / 100 +
    (props.vueHotspotBackgroundImage.offsetLeft - props.vueHotspot.clientLeft);
}

const hotSpotClickHandler = () => {
  if (props.isClickable) {
    emit('click');
  }
};
</script>

<style scoped>
.image-active-pulse-grow {
  animation: active-pulse-grow 1s ease-in-out infinite;
}

@keyframes active-pulse-grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
.image-pulse-grow {
  animation: pulse-grow 1s ease-in-out infinite;
}

@keyframes pulse-grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
    /* transform: scale(1.25) translateY(-8px); */
  }

  100% {
    transform: scale(1);
  }
}

#active-hotspot-img {
  position: relative;
}
</style>

<style>
.custum-popper-hotspot-animation {
  width: max-content;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  animation: custom-bounce-up 1s ease-in-out infinite;
}

@keyframes custom-bounce-up {
  0%,
  100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -4px); /* Adjust the vertical translation */
  }
}
</style>
